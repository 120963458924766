<template>
  <div ref="container" style="width: 100%; height: 100%"></div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      map: null,
    };
  },
  methods: {
    initMap() {
      this.map = new BMapGL.Map(this.$refs.container);
      this.map.centerAndZoom(new BMapGL.Point(105.62204, 29.420054), 20);
      this.map.enableScrollWheelZoom(true);
      var marker = new BMapGL.Marker(new BMapGL.Point(105.62204, 29.420054));
      //在地图上添加点标记
      this.map.addOverlay(marker);
      this.addMapControl();
    },
    //向地图添加控件
    addMapControl() {
      //平移缩放控件
      var navControl = new BMapGL.NavigationControl({
        anchor: BMAP_ANCHOR_TOP_LEFT,
        type: BMAP_NAVIGATION_CONTROL_ZOOM,
      });
      this.map.addControl(navControl);
    },
  },
  mounted() {
    this.initMap();
  },
};
</script>

<style lang="less" scoped>
</style>